import { getPercentage } from 'components/TextInput/Percentage';
import { IAboutYourLoanFormModel } from 'models/AboutTheLoanFormModel';
import { propertyCheckbox } from 'utils/commonUtils';
import { ILoanStatus } from '../models/ILoanStatus';

export enum PurposeType {
  Purchase = 'purchase',
  Refinance = 'refinance',
  CashoutRefinance = 'cashout-refinance'
}

enum LoanType {
  NotFhaOrVa = 'not-fha-or-va',
  FHA = 'fha',
  VA = 'va'
}

enum RefinanceFhaType {
  NormalRefinance = 'normal-refinance',
  FHAStreamline = 'fha-streamline'
}

enum RefinanceType {
  Normal = 'normal',
  Streamline = 'streamline',
  Empty = ''
}

interface IPurposeRequest {
  purposeType: string;
  loanTerm: string;
  propertyZipCode: string;
  loanType: string;
  refinanceFhaType: string;
  refinanceComplementaryInfo: {
    estimatedPropertyValue: number;
    currentLoanBalance: number;
    cashOutAmount: number;
    isHELOC?: boolean;
  };
  purchaseComplementaryInfo: {
    purchasePrice: number;
    downPaymentAmount: number | string;
  };
}

function getLoanType(loanType: string): LoanType {
  if (loanType === 'fha') return LoanType.FHA;
  if (loanType === 'va') return LoanType.VA;

  return LoanType.NotFhaOrVa;
}

function translateFhaType(refinanceType: string): RefinanceFhaType {
  if (!refinanceType) {
    return null;
  }

  if (refinanceType === 'normal') return RefinanceFhaType.NormalRefinance;

  return RefinanceFhaType.FHAStreamline;
}

function getRefinanceFHAtoForm(
  loanType: string,
  refinanceType: string
): RefinanceType {
  if (loanType !== LoanType.FHA) return RefinanceType.Empty;

  if (refinanceType === RefinanceFhaType.NormalRefinance)
    return RefinanceType.Normal;

  return RefinanceType.Streamline;
}

export function parsePurpose(
  purposeForm: IAboutYourLoanFormModel
): IPurposeRequest {
  const purposeType = purposeForm.purposeRadioButton;
  const loanType = getLoanType(purposeForm.loanType);

  const refinanceFhaType =
    loanType === LoanType.FHA
      ? translateFhaType(purposeForm.refinanceFhaType)
      : null;

  return {
    purposeType,
    propertyZipCode: purposeForm[purposeType].zipCode,
    loanType,
    refinanceFhaType,
    loanTerm: purposeForm.loanTerm,
    refinanceComplementaryInfo: {
      estimatedPropertyValue: Number(
        purposeForm[purposeType].estimatedPropertyValue || 0
      ),
      currentLoanBalance: Number(
        purposeForm[purposeType].currentLoanBalance || 0
      ),
      cashOutAmount: Number(purposeForm[purposeType].cashOutAmmount || 0),
      isHELOC: purposeForm[purposeType]?.isHELOC === 'yes'
    },
    purchaseComplementaryInfo: {
      purchasePrice: Number(purposeForm[purposeType].purchasePrize) || 0,
      downPaymentAmount: Number(
        parseFloat(purposeForm[purposeType].downPayment).toFixed(2)
      )
    }
  };
}

export function parseToForm(loanStatus: ILoanStatus): IAboutYourLoanFormModel {
  if (!loanStatus.purposeType) return null;
  const refinanceInfo = loanStatus.refinanceComplementaryInfo;
  const purchaseInfo = loanStatus.purchaseComplementaryInfo;

  const additionalLoanOptions = {
    optionOne: propertyCheckbox(loanStatus.isBorrowerVeteran),
    optionTwo: propertyCheckbox(loanStatus.haveMortgageLoans),
    optionThree: propertyCheckbox(loanStatus.wantMortgageImpounds)
  };
  const loanType =
    loanStatus?.loanType === LoanType.NotFhaOrVa ? 'no' : loanStatus?.loanType;

  const refinanceFhaType = getRefinanceFHAtoForm(
    loanStatus?.loanType,
    loanStatus?.refinanceFhaType
  );

  const zipCode = loanStatus.propertyZipCode || '';

  return {
    purposeRadioButton: loanStatus.purposeType,
    loanTerm: loanStatus?.loanTerm,
    loanType,
    refinanceFhaType,
    purchase: {
      purchasePrize: getValue(purchaseInfo?.purchasePrice),
      downPayment: getValue(purchaseInfo?.downPaymentAmount),
      downPaymentPercentage: getValue(
        getPercentage(
          purchaseInfo?.downPaymentAmount,
          purchaseInfo?.purchasePrice
        ).toFixed(0)
      ),
      zipCode,
      additionalLoanOptions
    },
    refinance: {
      zipCode,
      additionalLoanOptions,
      estimatedPropertyValue: getValue(refinanceInfo?.estimatedPropertyValue),
      currentLoanBalance: getValue(refinanceInfo?.currentLoanBalance),
      cashOutAmmount: getValue(refinanceInfo?.cashOutAmount),
      isHELOC: refinanceInfo?.isHELOC ? 'yes' : 'no'
    }
  };
}

function getValue(prop: any) {
  if (prop) {
    return prop?.toString();
  }

  return '';
}
